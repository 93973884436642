
  import {
    defineComponent,
    onActivated,
    ref,
    watch,
    getCurrentInstance,
  } from "vue";
  import banner from "@/components/common/Swiper.vue";
  import list from "@/components/common/list.vue";
  import productCard from "@/components/common/bnccProductCard.vue";
  import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
  import MenuTab from "@/components/common/MenuTab.vue";
  //  utils
  import api from "@/api/axios";
  //ts
  import { productMap, strainOrCellsMap } from "@/hooks/useProduct";
  import { nextTick } from "q";

  export default defineComponent({
    name: "Bncc",
    components: {
      banner,
      list,
      productCard,
      MenuTab,
    },
    setup() {
      const list = ref(null);
      const productList = ref(null);
      const strainurl = "/M/Server/GetProductLastjz";
      const cellsurl = "/M/Server/GetProductLastxb";
      const productparams = {
        anclassID: 1,
      };

      api.get("/M/Product").then((res): void => {
        list.value = res.data.obj.listimg;
      });

      //  切换列表菜单
      type Menu = "微生物菌种" | "科研细胞";
      const menu: Menu[] = ["微生物菌种", "科研细胞"];

      const route = useRoute();
      const router = useRouter();

      const index = ref(0);
      const currentMenuItem = ref(menu[index.value]);
      function initShowTabData() {
        const type = route.query.type;
        switch (type) {
          case "0":
            index.value = 0;
            route.meta.searchType = "bncc";
            break;
          case "1":
            index.value = 1;
            route.meta.searchType = "ceils";
            break;
          default:
            index.value = 0;
            route.meta.searchType = "bncc";
            break;
        }

        currentMenuItem.value = menu[index.value];
      }
      initShowTabData();
      function selectMenu(menuItem: Menu) {
        currentMenuItem.value = menuItem;
        switch (menuItem) {
          case "微生物菌种":
            router.replace({
              name: "Bncc",
              query: {
                type: 0
              }
            })
            break;
          case "科研细胞":
            router.replace({
              name: "Bncc",
              query: {
                type: 1
              }
            })
            break;
          default:
            router.replace({
              name: "Bncc",
              query: {
                type: 0
              }
            })
            break;
        }

      }
      onActivated(() => {
        initShowTabData();
        console.log(index.value);
        console.log(currentMenuItem.value);
      });
      watch(
              () => {
                return route.query;
              },
              (to, form) => {
                console.log(to, form);
                switch (to.type) {
                  case "0":
                    index.value = 0;
                    currentMenuItem.value = menu[0];
                    route.meta.searchType = "bncc";
                    break;
                  case "1":
                    index.value = 1;
                    currentMenuItem.value = menu[1];
                    route.meta.searchType = "ceils";
                    break;
                  default:
                    index.value = 0;
                    currentMenuItem.value = menu[0];
                    route.meta.searchType = "bncc";
                }
              }
      );

      const unMountComponent = ref(false);
      onBeforeRouteLeave((to, from) => {
        if (to.name != "bnccdetail") {
          unMountComponent.value = true;
          nextTick(() => {
            unMountComponent.value = false;
          });
        }
        return true;
      });
      return {
        list,
        productList,
        menu,
        index,
        currentMenuItem,
        selectMenu,
        strainurl,
        cellsurl,
        productMap,
        strainOrCellsMap,
        productparams,
        unMountComponent,
      };
    },
  });
